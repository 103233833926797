import { useContext, useState, useEffect } from 'react';
import { Input, Select } from '@mtb/ui';
import { toLocalizedString, defaultLocale, toInvariantString } from '../utils/locales';
import { FlagsContext, SettingsContext } from '../utils/context';
import classNames from 'classnames';
import { removeWrappedQuotes } from '../utils/model-utils';

export const InputTypes = {
  Numeric : 'numeric',
  Datetime: 'datetime',
  Text    : 'text'
};


export const LocalizedInput = props => {
  const {
    children,
    select = false,
    initialType = InputTypes.Numeric,
    initialValue,
    onChange,
    forceReset = false,
    allowQuotedInput = false,
    ...otherProps } = props;
  const flags = useContext(FlagsContext);
  const settings = useContext(SettingsContext);
  const [inputObject, setInputObject] = useState();
  const [regionCode, setRegionCode] = useState();

  const getInvariant = (localized) => {
    if (initialType === InputTypes.Text) {
      return { value: localized, type: InputTypes.Text };
    }

    let invariant = toInvariantString(localized, regionCode);
    if (allowQuotedInput) {
      const cleanedInv = toInvariantString(removeWrappedQuotes(localized), regionCode);
      if (cleanedInv.type === InputTypes.Numeric) {
        invariant = cleanedInv;
      }
    }
    return invariant;
  };

  useEffect(() => {
    setRegionCode(flags.region && settings.locale?.regionCode ? settings.locale.regionCode : defaultLocale.value);
  }, [flags.region, settings.locale?.regionCode]);

  useEffect(() => {
    // Re-evaluate input on region change, updating the local value to new region if numeric
    setInputObject(inputObj => {
      if (!inputObj || !regionCode || initialType === InputTypes.Text) {
        return;
      }
      const quote = allowQuotedInput && removeWrappedQuotes(inputObj.localized) !== inputObj.localized ? inputObj.localized[0] : '';
      const localized = inputObj.type === InputTypes.Numeric ?
        `${quote}${toLocalizedString(inputObj.invariant, regionCode)}${quote}` : inputObj.invariant;
      const invariantObj = getInvariant(localized);
      if (invariantObj.type !== inputObj.type) {
        // OG regionCode = FR and input = '1.2' type = 'text', if we switch to EN
        // need to re-evaluate input with the new regionCode. '1.2' is now a valid numeric input
        onChange(invariantObj);
      }
      return { localized, invariant: invariantObj.value, type: invariantObj.type };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionCode]);

  useEffect(() => {
    // Use initial value to generate localized input value on first render, if inputObject not set
    // or if forceReset is true (e.g. in settings when cancelled was called)
    if ((!inputObject?.invariant || forceReset) && initialValue !== undefined) {
      const localized = initialType === InputTypes.Numeric ? toLocalizedString(initialValue, regionCode) : initialValue;
      setInputObject({ localized: localized, invariant: initialValue.toString(), type: initialType });
    }
  }, [inputObject?.invariant, forceReset, regionCode, initialType, initialValue]);

  const handleChange = (event) => {
    const invariant = getInvariant(event.target.value);
    setInputObject({ localized: event.target.value, invariant: invariant.value, type: invariant.type });
    onChange(invariant);
  };

  const InputComponent = (<Input
    {...otherProps}
    className={classNames('LocalizedInput', otherProps.className)}
    value={inputObject?.localized ?? ''}
    onChange={handleChange} />);
  return (<>
    {select ?
      <Select
        input={InputComponent}
        value={inputObject?.localized ?? ''}>
        {children}
      </Select> :
      InputComponent
    }
  </>);

};
import React, { useEffect, useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import Keys from '../api/keys';
import ApiKeyDisplay from './apikeydisplay';
import { WarningIcon } from '../images';
import { Scopes } from '../utils/scopes';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mtb/ui';
import { FlagsContext } from '../utils/context';

const ErrorCodes = {
  InvalidDate: 'InvalidDate',
  InvalidName: 'InvalidName'
};

const dateToString = date => date.split('T')[0];

const ApiKeyCreate = props => {
  const {
    t,
    onCancel,
    setDialog,
    reloadKeys,
    user,
    target,
    defaultKeyName,
    showWarning,
    scopes,
    timezone
  } = props;

  const flags = useContext(FlagsContext);

  const [busy, setBusy] = useState(false);
  const [keyName, setKeyName] = useState(defaultKeyName ?? '');
  const [endDate, setEndDate] = useState(dateToString(moment.utc().startOf('day').add(30, 'days').toISOString()));
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [authorizedScopes, setAuthorizedScopes] = useState(scopes || []);
  const [errorCode, setErrorCode] = useState();

  const showApiKeyDisplay = useCallback(async (apiKey) => {
    setDialog(
      <ApiKeyDisplay
        apiKey={apiKey}
        showWarning={!showWarning}
        user={user}
        onCancel={() => setDialog()} />
    );
  }, [setDialog, showWarning, user]);

  const addApiKey = async () => {
    setBusy(true);
    try {
      const { apiKey } = await Keys.create(
        endDate,
        keyName,
        JSON.stringify(authorizedScopes),
        target);
      showApiKeyDisplay(apiKey);
      reloadKeys();
    } catch (err) {
      if (err.message.includes(ErrorCodes.InvalidDate)) {
        setErrorCode(ErrorCodes.InvalidDate);
      } else if (err.message.includes(ErrorCodes.InvalidName)) {
        setErrorCode(ErrorCodes.InvalidName);
      }
    } finally {
      setBusy(false);
    }
  };

  const calculateDate = (flags, timezone, adjust) => {
    const dateTime = (flags?.timezone ? moment.tz(timezone) : moment.utc()).startOf('day');
    const value = dateToString((adjust ? adjust(dateTime) : dateTime).format());
    return value;
  };

  useEffect(() => {
    setMinDate(calculateDate(flags, timezone, (dateTime) => dateTime.add(1, 'day')));
    setMaxDate(calculateDate(flags, timezone, (dateTime) => dateTime.add(1, 'year')));
  }, [flags, timezone]);

  useEffect(() => {
    setErrorCode();
  }, [keyName, endDate]);

  const toggleScope = types => {
    types.forEach(type => {
      if (authorizedScopes.includes(type)) {
        setAuthorizedScopes(authorizedScopes.filter(scope => scope !== type));
      } else {
        setAuthorizedScopes([...authorizedScopes, type]);
      }
    });
  };

  return (
    <div className="dialogContainer">
      <div className="modalDialog apiKeyCreateDialog">
        <h3>{t('addApiKey')}</h3>
        {showWarning && (
          <>
            <div className='instructions'>{t('enterKeyNameExpirationDate')}</div>
            <div className='warning'>
              <WarningIcon />
              <div>{t('addApiKeyWarning')}</div>
            </div>
          </>
        )}
        <div className='value-pair'>
          <span>{t('keyName')}</span>
          <input
            className={classNames('keyName', { 'error': errorCode === ErrorCodes.InvalidName })}
            maxLength={50}
            placeholder={t('enterKeyName')}
            type="text"
            value={keyName}
            onChange={({ target: { value } }) => setKeyName(value)} />
        </div>
        {errorCode === ErrorCodes.InvalidName && (
          <div className='error'>{t('validateApiKeyName')}</div>
        )}
        <div className='value-pair'>
          <span>{t('expiration')}</span>
          {flags.region ? <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              className='DatePicker'
              inputFormat="YYYY-MM-DD"
              maxDate={maxDate}
              minDate={minDate}
              renderInput={({ ...params }) => {
                return <TextField
                  {...params}
                  error={ errorCode === ErrorCodes.InvalidDate } />;
              }}
              value={endDate}
              onChange={(value) => setEndDate(dateToString(value.format()))} />
          </LocalizationProvider> :
            <input
              className={classNames({ 'error': errorCode === ErrorCodes.InvalidDate })}
              max={maxDate}
              min={minDate}
              type="date"
              value={endDate.toString()}
              onChange={({ target: { value } }) => setEndDate(value)} />
          }

        </div>
        {errorCode === ErrorCodes.InvalidDate && (
          <div className='error'>{t('validateApiKeyDate')}</div>
        )}
        {!scopes && (
          <div>
            <h4>{t('scopes')}</h4>
            <label className="scopes-label">
              {t('apiKeyScopeInfo')}
            </label>
            <div className="scopes">
              <div onClick={() => toggleScope([Scopes.ModelsRead, Scopes.DeploymentsRead])}>
                <input
                  checked={authorizedScopes.includes(Scopes.ModelsRead)}
                  name="manage-models"
                  readOnly
                  type="checkbox" />
                <label htmlFor="manage-models">{t('manageModels')}</label>
              </div>
              <div onClick={() => toggleScope([Scopes.ScoreWrite, Scopes.StabilityWrite])}>
                <input
                  checked={authorizedScopes.includes(Scopes.ScoreWrite)}
                  name="score-models"
                  readOnly
                  type="checkbox" />
                <label htmlFor="score-models">{t('scoreModels')}</label>
              </div>
            </div>
          </div>
        )}
        <div className="buttons">
          <button
            className="confirmButton"
            disabled={busy}
            onClick={addApiKey}>
            {t('create')}
          </button>
          <button
            className="cancelButton"
            disabled={busy}
            onClick={onCancel}>
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyCreate;
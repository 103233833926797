/* eslint-disable max-len */
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { serializeError } from 'serialize-error';
import { Checkbox, FormControlLabel, MenuItem, Select } from '@mtb/ui';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import { timeSeriesOverlaidStabilityConfig } from '../utils/chart-options';
import { SERIES_COLORS } from '../utils/chart-utils';
import Deployments from '../api/deployments';
import Reports from '../api/reports';
import { getPromotions } from '../utils/model-utils';
import { Statuses } from './Deployments';
import { ReportPeriodSetting, getIntervalSize } from './ReportHeader';
import EmptyReport from './EmptyReport';
import { ERROR_CODES, getErrorMessage } from '../utils/errors/report-errors';
import { ErrorsContext, FlagsContext, SettingsContext } from '../utils/context';
import { SessionContext, SessionStatus } from './SessionWrapper';
import { InputTypes, LocalizedInput } from './Inputs';
import { defaultLocale, toLocalizedString, getOffset } from '../utils/locales';

// Maps stability metrics to their L10N keys
export const METRICS = {
  AreaUnderCurve              : 'areaUnderCurve',
  AvgPredictedResponse        : 'averagePredictedResponse',
  AvgPredictedProbabilityEvent: 'averagePredictedProbabilityEvent',
  ClassificationAccuracy      : 'classificationAccuracy',
  MAD                         : 'mad',
  ProportionClassifiedEvent   : 'proportionClassifiedEvent',
  RSquared                    : 'rSquared'
};

// Enable export module for converting charts to SVG for download
exporting(Highstock);

const getStabilityTitleMessage = (t, errorCode) => {
  if (errorCode === ERROR_CODES.PendingReplay) {
    return t('reportsArePending');
  }
  return t('stabilityDataRequired');
};

const getMetricOptions = (responseType) => {
  return responseType === 'continuous' ?
    [
      METRICS.AvgPredictedResponse,
      METRICS.RSquared,
      METRICS.MAD
    ] :
    [
      METRICS.AvgPredictedProbabilityEvent,
      METRICS.ProportionClassifiedEvent,
      METRICS.ClassificationAccuracy,
      METRICS.AreaUnderCurve
    ];
};

const getAUCResults = (data, level) => {
  if (data?.auc_results?.length === 1) {
    return data.auc_results[0];
  }
  return data?.auc_results?.find(series => series.name.toString() === level?.toString());
};

const getSeriesData = (data, metric, level) => {
  const classData = getAUCResults(data, level);
  switch (metric) {
    case METRICS.AvgPredictedResponse:
      return data?.mean_response_timeseries;
    case METRICS.RSquared:
      return data?.r_squared_timeseries;
    case METRICS.MAD:
      return data?.mad_timeseries;
    case METRICS.ClassificationAccuracy:
      return data?.accuracy_timeseries;
    // Remaining cases need data for a specific response level
    case METRICS.AvgPredictedProbabilityEvent:
      return classData?.mean_probability;
    case METRICS.ProportionClassifiedEvent:
      return classData?.class_proportion;
    case METRICS.AreaUnderCurve:
      return classData?.auc_timeseries;
    default:
  }
};

const getPromotionRanges = (promotionsLog, modelId, deployment) => {
  const ranges = [];
  promotionsLog.forEach((entry, i) => {
    const [, start, id] = entry;
    const startDate = i === 0 ? undefined : start;
    if (id === modelId) {
      ranges.push([Date.parse(startDate), Date.parse(promotionsLog[i + 1]?.[1])]);
    }
  });

  // No promotions exist, but model is the champion
  if (!promotionsLog.length && deployment.champion === modelId) {
    ranges.push([undefined, undefined]);
  }

  return ranges;
};

const getConfig = (t, flags, data, period, promotions, responseType, metric, level, showPromotions, deployment, xMin, xMax, updateUserSettings) => {
  if (!data || !Object.keys(data).length || !metric) {
    return;
  }

  const validIds = Object.keys(data).filter(key => data[key]);
  if (!validIds.length) {
    return;
  }

  const updateXAxisRange = (min, max) => updateUserSettings({ xMin: min, xMax: max });

  return timeSeriesOverlaidStabilityConfig(
    t,
    flags,
    validIds.map(modelId => {
      const modelData = data[modelId];
      return {
        name           : modelData?.model_name,
        color          : modelData?.series_color,
        legendIndex    : modelData?.legend_index,
        data           : getSeriesData(modelData, metric, level) ?? { time_values: [], data_values: [] },
        promotionRanges: getPromotionRanges(promotions, modelId, deployment)
      };
    }),
    getIntervalSize(period),
    showPromotions ? promotions.map(p => [p[0], p[1]]) : [],
    responseType === 'categorical',
    flags.timezone ? {
      timezone      : deployment.timezone ?? defaultLocale.timezone,
      adjustedOffset: deployment.adjusted_offset ?? getOffset(deployment.timezone, 0)
    } : undefined,
    xMin,
    xMax,
    updateXAxisRange
  );
};

const fetchStabilityProperties = async (modelIds, reportSettings) => {
  const { start, end, period } = reportSettings;
  const data = {};
  for (const id of modelIds) {
    data[id] = await Reports.getStabilityProperties('models', id, start, end, ReportPeriodSetting[period]);
  }
  return data;
};

const fetchStabilityData = async (modelIds, reportSettings, forceRefresh) => {
  const { start, end, period } = reportSettings;
  const data = {};
  for (const id of modelIds) {
    let results;
    try {
      if (forceRefresh) {
        // Check to see if there is a new report that isn't dirty first.
        const stabilityProperties = await fetchStabilityProperties(modelIds, reportSettings);
        results = Object.values(stabilityProperties).some(metadata => metadata?.isdirty === 'true')
          ? await Reports.generateStabilityReport('models', id, start, end, ReportPeriodSetting[period])
          : await Reports.getStabilityData('models', id, start, end, ReportPeriodSetting[period]);
      } else {
        results = await Reports.getStabilityData('models', id, start, end, ReportPeriodSetting[period]);
      }
    } catch (err) {
      if (err.status !== 404) {
        throw err;
      }
      results = await Reports.generateStabilityReport('models', id, start, end, ReportPeriodSetting[period]);
    }
    const report = results.model ? results : null;
    data[id] = { report, errorCode: results.errorcode, creating: results.status === Statuses.Creating };
  }
  return data;
};

const getReportsByModel = data => {
  const entries = Object.entries(data).map(([key, value]) => [key, value.report]);
  return Object.fromEntries(entries);
};

const StabilityReport = props => {
  const {
    id,
    deployment,
    refresh,
    setRefresh,
    setShowRefresh,
    setLastUpdated,
    setHasReport,
    reportSettings,
    userSettings,
    updateUserDeploymentSettings,
    updateUserReportSettings
  } = props;

  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);
  const flags = useContext(FlagsContext);
  const settings = useContext(SettingsContext);
  const session = useContext(SessionContext);

  const [rawReportData, setRawReportData] = useState();
  const [reportData, setReportData] = useState();
  const [promotions, setPromotions] = useState();
  const [deploymentModels, setDeploymentModels] = useState();
  const [responseDataType, setResponseDataType] = useState();
  const [responseType, setResponseType] = useState();
  const [responseLevels, setResponseLevels] = useState();
  const [metricOptions, setMetricOptions] = useState();
  const [graph, setGraph] = useState(null);
  const [reloadData, setReloadData] = useState(true);
  const [enableQuery, setEnableQuery] = useState(true);
  const [errorCode, setErrorCode] = useState();
  const [isOutOfDate, setIsOutOfDate] = useState(false);
  const [, setChartImageData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showReport, setShowReport] = useState(false);
  const [disableLevelSelect, setDisableLevelSelect] = useState(false);

  const { xMin, xMax, stabilityMetric: metric, stabilityResponseLevel: responseLevel, showPromotions } = userSettings;
  const elementId = 'stability-report';

  const deploymentModelIds = [
    deployment.champion,
    ...(deployment.challengers ? JSON.parse(deployment.challengers) : [])
  ];

  const downloadQueryId = `stability-${id}-download-${JSON.stringify(reportSettings)}`;
  useQuery(downloadQueryId, () => fetchStabilityData(deploymentModelIds, reportSettings, refresh),
    {
      onSuccess: async data => {
        const results = Object.values(data);
        setEnableQuery(results.some(result => result.creating));
        setErrorCode(results.find(result => result.errorCode)?.errorCode);
        setReportData(null);
        setRawReportData(data);
        setRefresh(false);
      },
      onError: error => {
        console.error(error);
        onError(serializeError(error));
      },
      enabled                    : !!enableQuery && session?.sessionStatus !== SessionStatus.Expired,
      refetchInterval            : 5000, // 5 seconds
      refetchIntervalInBackground: true
    });

  const propertiesQueryId = `stability-${id}-properties-${JSON.stringify(reportSettings)}`;
  useQuery(propertiesQueryId, () => fetchStabilityProperties(deploymentModelIds, reportSettings),
    {
      onSuccess: async data => {
        const outOfDate = Object.values(data).some(metadata => metadata?.isdirty === 'true');
        setIsOutOfDate(outOfDate);
        const lastUpdated = Object.values(data).reduce((timestamps, current) => {
          current.lastupdated && timestamps.push(moment(current.lastupdated));
          return timestamps;
        }, []);
        setLastUpdated(lastUpdated.length ? moment.max(lastUpdated) : undefined);
      },
      onError: error => {
        console.error(error);
        onError(serializeError(error));
      },
      enabled                    : !enableQuery && !isOutOfDate && session?.sessionStatus !== SessionStatus.Expired,
      refetchInterval            : 5000, // 5 seconds
      refetchIntervalInBackground: true
    });

  useEffect(() => {
    let current = true;
    if (reloadData) {
      (async () => {
        const [promotionsLog, deploymentModels] = await Promise.all([
          Deployments.getPromotions(id),
          Deployments.getDeploymentModels(id)
        ]);
        // Convert raw promotions log (timestamp/guids) to readable list with model names.
        const promotions = promotionsLog.length ? await getPromotions(t, promotionsLog) : [];
        if (current) {
          setPromotions(promotions);
          setDeploymentModels(deploymentModels);
          setReloadData(false);
        }
      })();
    }
    return () => current = false;
  }, [t, id, reloadData]);

  useEffect(() => {
    if (deploymentModels && rawReportData) {
      const data = { ...rawReportData };
      for (const { id, name, seriescolor } of deploymentModels) {
        if (data[id].report) {
          data[id].report.model_name = name;
          data[id].report.series_color = seriescolor;
          data[id].report.legend_index = SERIES_COLORS.indexOf(seriescolor);
        }
      }
      setReportData(getReportsByModel(data));
    }
  }, [deploymentModels, rawReportData]);

  const reset = () => {
    setReportData(null);
    setRawReportData(null);
    setIsOutOfDate(false);
    // Models and promotions are needed first
    setDeploymentModels();
    setPromotions();
    setEnableQuery(true);
    setReloadData(true);
    setIsLoading(true);
    setShowReport(false);
    setDisableLevelSelect(false);
  };

  useEffect(reset, [reportSettings, metricOptions]);
  useEffect(() => {
    if (refresh) {
      reset();
    }
  }, [refresh]);

  useEffect(() => {
    setShowRefresh(isOutOfDate);
  }, [isOutOfDate, setShowRefresh]);

  useEffect(() => {
    const response = deployment.schema.response;
    setResponseDataType(response.dataType);
    setResponseType(response.type);
    setResponseLevels(response.classes);
    const metrics = getMetricOptions(response.type);
    setMetricOptions(metrics);
  }, [id, deployment.schema]);

  const disableMetricOption = useCallback(metric => {
    const modelIds = Object.keys(reportData ?? {});
    return !modelIds.some(modelId => getSeriesData(reportData[modelId], metric, responseLevel));
  }, [reportData, responseLevel]);

  const chartExportCallback = useCallback(chartRef => {
    if (chartRef) {
      setChartImageData(chartRef.chart.getSVGForExport({}, {}));
    }
  }, [setChartImageData]);

  useEffect(() => {
    if (enableQuery || (flags.isr && (xMin === undefined || xMax === undefined))) {
      // Stability data hasn't been completely fetched for all models.
      // Wait instead of generating a partial graph.
      return;
    }
    setGraph(null);
    const hasData = reportData && Object.values(reportData).some(value => value);
    if (hasData && disableMetricOption(metric)) {
      // A new report was fetched that doesn't have valid data for the current metric.
      updateUserDeploymentSettings({ stabilityMetric: metricOptions?.[0] });
      return;
    }
    const graphConfig = getConfig(t, flags, reportData, reportSettings.period, promotions, responseType, metric,
      responseLevel, showPromotions, deployment, xMin, xMax, updateUserReportSettings);
    if (graphConfig) {
      setGraph(<HighchartsReact
        ref={chartExportCallback}
        highcharts={Highstock}
        options={graphConfig} />);
    }
    // Cleanup function
    return () => setGraph(null);
  // eslint-disable-next-line max-len, react-hooks/exhaustive-deps
  }, [t, reportSettings, reportData, promotions, responseType, responseLevel, flags, settings.locale?.regionCode,
    metric, showPromotions, deployment, disableMetricOption, metricOptions, chartExportCallback, enableQuery, xMin, xMax]);

  // Current UI state
  useEffect(() => {
    const loading = enableQuery || reloadData || refresh || !reportData || !promotions || !metricOptions;
    const isEmpty = reportData && Object.values(reportData).every(value => !value);
    const hasReport = !loading && !isEmpty;

    setIsLoading(loading);
    setShowReport(hasReport);
    setDisableLevelSelect(metric === METRICS.ClassificationAccuracy ||
      (responseLevels?.length === 2 && metric === METRICS.AreaUnderCurve));
    setHasReport(hasReport);
  // eslint-disable-next-line max-len
  }, [enableQuery, metricOptions, promotions, refresh, reloadData, reportData, responseLevels, metric, setHasReport]);

  return (
    <div
      className='stability-report'
      id={elementId}>
      <div className="report-dashboard-container">
        {isLoading &&
          <EmptyReport
            description={t('updatingReportDescription')}
            title={t('updatingReport')} />}
        {!showReport && !isLoading &&
          <EmptyReport
            description={getErrorMessage(t, errorCode, 'stability')}
            title={getStabilityTitleMessage(t, errorCode)} />}
        {showReport &&
          <div className='stability-report'>
            <div className="metric-settings">
              <div className='control'>
                <label>{t('metric')}</label>
                <Select
                  className="metric-select"
                  name="metric-select"
                  value={metric}
                  onChange={e => updateUserDeploymentSettings({ stabilityMetric: e.target.value })}>
                  {Object.values(metricOptions).map(option => (
                    <MenuItem
                      key={option}
                      disabled={disableMetricOption(option)}
                      value={option}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {responseLevels?.length >= 2 &&
                <div className='control'>
                  <label className={classNames({ 'disabled': disableLevelSelect })}>
                    {t('responseLevel')}
                  </label>
                  <LocalizedInput
                    className="level-select"
                    disabled={disableLevelSelect}
                    initialType={responseDataType}
                    initialValue={responseLevel}
                    name="level-select"
                    select
                    onChange={({ value }) => updateUserDeploymentSettings({ stabilityResponseLevel: value.toString() })}>
                    {Object.values(responseLevels).map(option => {
                      const value = responseDataType === InputTypes.Numeric && flags.region ?
                        toLocalizedString(option, settings.locale?.regionCode) : option;
                      return <MenuItem
                        key={option}
                        value={value}>
                        {value}
                      </MenuItem>;
                    })}
                  </LocalizedInput>
                </div>}
              <div className='control'>
                <FormControlLabel
                  control={<Checkbox
                    checked={showPromotions}
                    size="small" />}
                  label={t('showPromotions')}
                  onChange={e => updateUserDeploymentSettings({ showPromotions: e.target.checked })} />
              </div>
            </div>
            <div className='stability-plot'>
              <div data-html2canvas-ignore>
                {graph}
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
};

export default StabilityReport;

export class ApiError extends Error {
  constructor(status, statusText, body) {
    super(`${status} ${statusText} ${body ? JSON.stringify(body) : ''}`);
    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

export const apiFetch = async (resource, init) => {
  let response;
  try {
    response = await fetch(resource, init);
  } catch (e) {
    console.error(e);
  }
  if (!response) {
    if (window.location.pathname !== '/embed/upload') {
      window.location = '/';
    }
    return;
  }
  if (!response.ok && response.status === 401) {
    window.location = `/account/login?wctx=${encodeURIComponent(window.location.pathname)}`;
    return;
  }
  const contentType = response.headers.get('content-type');
  const json = contentType && contentType.indexOf('application/json') !== -1;
  if (!response.ok) {
    throw new ApiError(response.status, response.statusText, json ? await response.json() : await response.text());
  }
  return json ? await response.json() : await response.text();
};

export const apiDownload = async (resource, init) => {
  let response;
  try {
    response = await fetch(resource, init);
  } catch (e) {
    console.error(e);
  }
  if (!response) {
    window.location = '/';
    return;
  }
  if (!response.ok && response.status === 401) {
    window.location = `/account/login?wctx=${encodeURIComponent(window.location.pathname)}`;
    return;
  }
  const contentType = response.headers.get('content-type');
  const json = contentType && contentType.indexOf('application/json') !== -1;
  if (!response.ok) {
    throw new ApiError(response.status, response.statusText, json ? await response.json() : await response.text());
  }
  return response.blob();
};
import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Button,
  Select,
  MenuItem,
} from '@mtb/ui';
import { CopyIcon } from '../images';
import {
  escapeSingleQuotes,
  encodeEnclosingBrackets
} from '../utils/model-utils';
import { DataTypes, RequestTypes } from './Integration';
import { InputTypes } from './Inputs';
import { FlagsContext, SettingsContext } from '../utils/context';
import { availableLocales } from '../utils/locales';

import './Integration.scss';

export const MethodTypes = {
  CURLGet : 'curlGet',
  CURLPost: 'curlPost'
};

const getEndpoint = requestType => {
  let endpoint = `${window.location.origin}/api`;
  endpoint = endpoint.concat(`/${requestType === RequestTypes.Prediction ? 'score' : 'stability'}`);
  return endpoint;
};

const getParams = values => {
  const params = values.reduce((prev, row) => {
    Object.entries(row).forEach(([key, value]) => {
      prev[key] = prev[key] ? [...prev[key], value] : [value];
    });
    return prev;
  }, {});

  // All columns are optional
  Object.keys(params).forEach(fieldName => {
    if (params[fieldName]?.every(({ value }) => !value.toString().length)) {
      delete params[fieldName];
    }
  });

  return params;
};

// Returns a json string with quotes removed from numeric values, without the loss of precision of using Number().
const getPayload = (params) => {
  const payload = [];
  for (const [key, values] of Object.entries(params)) {
    const convertedValues = values.map(({ value, type }) => {
      if (value === '') {
        return '""';
      }

      if (type === InputTypes.Text) {
        return JSON.stringify(value);
      }
      return value;
    });
    payload.push(`${JSON.stringify(key)}:[${convertedValues.join(',')}]`);
  }
  return escapeSingleQuotes(`{${payload.join(',')}}`);
};

const getSearchParams = (params) => {
  encodeEnclosingBrackets(params);
  const updatedParams = Object.fromEntries(Object.entries(params).map(([k, { value, _ }]) => [k, value]));

  // All columns are optional
  Object.keys(updatedParams).forEach(fieldName => {
    if (!updatedParams[fieldName]?.toString().length) {
      delete updatedParams[fieldName];
    }
  });

  return new URLSearchParams(updatedParams);
};

const CodePanel = ({ t, values, requestType, dataType, dataFile, methodType, updateMethodType }) => {
  const [copied, setCopied] = useState(false);
  const [curlGET, setCurlGET] = useState();
  const [curlPOST, setCurlPOST] = useState();
  const [contents, setContents] = useState();

  const settings = useContext(SettingsContext);
  const flags = useContext(FlagsContext);

  useEffect(() => {
    if (!values?.length) {
      return;
    }
    const endpoint = getEndpoint(requestType);
    if (dataType === DataTypes.DataFile) {
      const region = availableLocales.find(locale => locale.value === settings.locale?.regionCode);
      const acceptsLanguage = flags?.region ? ` -H "${flags?.l10n ? 'mtb-region' : 'Accept-Language'}: ${region?.value || 'en-US'}"` : '';
      const file = dataFile?.name ? `filepath/${dataFile.name}` : '<name_of_file>';
      setCurlPOST(`curl -X POST -F 'data=@${file}'${acceptsLanguage} -H "ApiKey: API_KEY" '${endpoint}'`);
    } else {
      const postParams = getParams(dataType === DataTypes.SingleRow ? [values[0]] : values);
      setCurlPOST(`curl -s -X POST -H "ApiKey: API_KEY" '${endpoint
      }' -H 'Content-Type: application/json' --data '${getPayload(postParams)}'`);
      if (dataType === DataTypes.SingleRow) {
        const getParams = values[0];
        setCurlGET(`curl -s -H "ApiKey: API_KEY" '${endpoint}?${getSearchParams(getParams)}'`);
      }
    }
  }, [values, requestType, dataType, dataFile?.name, settings.locale?.regionCode, flags]);

  useEffect(() => {
    if (!copied) {
      return;
    }
    const timer = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  useEffect(() => {
    setContents(methodType === MethodTypes.CURLGet ? curlGET : curlPOST);
  }, [curlGET, curlPOST, methodType]);

  return (
    <div className='curl'>
      <FormControl className='options'>
        <FormControl className='select'>
          <FormLabel>{t('method')}</FormLabel>
          <Select
            className='modeler-select-field'
            value={methodType}
            onChange={({ target: { value } }) => updateMethodType(value)}>
            {Object.values(MethodTypes).map(type => {
              return ((dataType === DataTypes.SingleRow || type === MethodTypes.CURLPost)) &&
                (type !== MethodTypes.CURLGet || requestType !== RequestTypes.Stability) &&
                <MenuItem
                  key={type}
                  value={type}>
                  {t(type)}
                </MenuItem>;
            })}
          </Select>
        </FormControl>
        <Button
          color="default"
          size="small"
          variant="text"
          onClick={() => {
            navigator.clipboard.writeText(contents);
            setCopied(true);
          }}>
          <CopyIcon />
          {t(copied ? 'copied' : 'copy')}
        </Button>
      </FormControl>
      <div className='values'>
        <textarea
          defaultValue={contents}
          readOnly
          onFocus={({ target }) => target.select()} />
      </div>
    </div>
  );
};

export default CodePanel;
import classNames from 'classnames';
import Papa from 'papaparse';
import { serializeError } from 'serialize-error';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mtb/ui';
import { WarningIcon } from '../images';
import { FileUpload } from './FileUpload';
import { MAX_FILE_SIZE_10_MB } from '../utils/file-utils';
import { extractMSSWorksheetColumnNames } from '../utils/model-file-utils';
import ValidationDialog from './ValidationDialog';
import Models from '../api/models';
import './dialogs.scss';
import './ImportBaselineDataDialog.scss';
import { ErrorsContext, FlagsContext, SettingsContext } from '../utils/context';
import { availableLocales, defaultLocale } from '../utils/locales';

const ImportBaselineDataDialog = ({
  modelId,
  modelName,
  schema,
  setIsUploadingBaseline,
  closeDialog,
  setBusy,
  update
}) => {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);
  const flags = useContext(FlagsContext);
  const settings = useContext(SettingsContext);

  const defaultSelectedOption = useMemo(() => t('selectDataColumn'), [t]);
  const defaultOptions = useMemo(() => [defaultSelectedOption], [defaultSelectedOption]);
  const defaultRowValues = useMemo(() => [
    schema.response,
    ...Object.entries(schema.predictors).reduce((prev, [key, value]) => {
      prev.push({ ...value, name: key });
      return prev;
    }, [])
  ].reduce((acc, cur) => ({
    ...acc, [cur.name]: defaultSelectedOption
  }), {}), [schema, defaultSelectedOption]);

  const [fileName, setFileName] = useState('');
  const [inputFile, setInputFile] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [rowValues, setRowValues] = useState(defaultRowValues);
  const [allOptions, setAllOptions] = useState(defaultOptions);
  const [availableOptions, setAvailableOptions] = useState(defaultOptions);
  const [showMissingPredictorsDialog, setShowMissingPredictorsDialog] = useState(false);
  const [showNoPredictorsDialog, setShowNoPredictorsDialog] = useState(false);
  const [showFileSizeLimitDialog, setShowFileSizeLimitDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [region, setRegion] = useState(defaultLocale);

  const getUsedOptions = useCallback((rowVals) => Object.values(rowVals).reduce((acc, selectedValue) => {
    if (selectedValue !== defaultSelectedOption) {
      acc.push(selectedValue);
    }
    return acc;
  }, []), [defaultSelectedOption]);

  const handleNewColumns = useCallback((columnNames) => {
    setAllOptions(defaultOptions.concat(columnNames));
    const lowerColumnNames = columnNames.map((c) => c.toLowerCase());
    setRowValues(prevRowValues => {
      for (const key in prevRowValues) {
        if (lowerColumnNames.includes(key.toLowerCase())) {
          prevRowValues[key] = columnNames[lowerColumnNames.indexOf(key.toLowerCase())];
        } else {
          prevRowValues[key] = defaultSelectedOption;
        }
      }
      setAvailableOptions(defaultOptions.concat(columnNames.filter(option =>
        !getUsedOptions(prevRowValues).includes(option)
      )));
      return prevRowValues;
    });
  }, [getUsedOptions, defaultSelectedOption, defaultOptions]);

  const importBaselineData = useCallback(async () => {
    try {
      setBusy(true);
      setIsUploadingBaseline(true);
      closeDialog();
      const data = new FormData();
      data.append('data', inputFile);
      data.append('metadata', JSON.stringify({
        mapping: Object.entries(rowValues).reduce((acc, [variable, selected]) => {
          if (selected !== defaultSelectedOption) {
            acc.push({ variable, column: selected });
          }
          return acc;
        }, [])
      }));
      await Models.uploadBaselineData(modelId, data);
      await update();
    } catch (e) {
      onError(serializeError(e));
    } finally {
      setBusy(false);
      setIsUploadingBaseline(false);
    }
  /* eslint-disable-next-line max-len */
  }, [modelId, inputFile, rowValues, onError, setBusy, setIsUploadingBaseline, closeDialog, update, defaultSelectedOption]);

  const handleSubmit = useCallback(async () => {
    if (inputFile?.size > MAX_FILE_SIZE_10_MB) {
      setShowFileSizeLimitDialog(true);
      return;
    }
    // Ignore if the user maps the response variable.
    const rowValuesResponseRemoved = { ...rowValues };
    const variables = Object.keys(rowValuesResponseRemoved);
    delete rowValuesResponseRemoved[variables[0]];
    if (Object.values(rowValuesResponseRemoved).every(selected => selected === defaultSelectedOption)) {
      // If all predictors are missing, don't allow the user to submit.
      setShowNoPredictorsDialog(true);
    } else if (Object.values(rowValuesResponseRemoved).some(selected => selected === defaultSelectedOption)) {
      // If any predictors are missing, warn, but allow the user to submit from a secondary dialog.
      setShowMissingPredictorsDialog(true);
    } else {
      await importBaselineData();
    }
  }, [inputFile, rowValues, importBaselineData, defaultSelectedOption]);

  const handleSetInputFile = useCallback((file) => {
    setRowValues(defaultRowValues);
    setInputFile(file);
  }, [defaultRowValues, setInputFile]);

  useEffect(() => {
    let current = true;
    (async () => {
      if (current && inputFile) {
        const extension = inputFile.name.split('.').pop().toUpperCase();
        switch (extension) {
          case 'CSV':
            Papa.parse(inputFile, {
              header       : true,
              delimiter    : region.listSeparator,
              dynamicTyping: true,
              complete     : (results) => {
                handleNewColumns(results.meta.fields);
                setIsValidFile(true);
              }
            });
            break;
          case 'MWX':
            const colummNames = await extractMSSWorksheetColumnNames(inputFile);
            handleNewColumns(colummNames);
            setIsValidFile(true);
            break;
          default:
            setValidationMessage(t('unsupportedFileType', { extension }));
            setIsValidFile(false);
            break;
        }
      }
    })();
    return () => current = false;
  }, [inputFile, handleNewColumns, region.listSeparator, t]);

  useEffect(() => {
    const regionCode = flags.region && settings.locale?.regionCode ? settings.locale?.regionCode : 'en-US';
    setRegion(availableLocales.find(al => al.value === regionCode));
  }, [flags.region, settings.locale?.regionCode]);

  useEffect(() => {
    setAvailableOptions(allOptions.filter(column => !getUsedOptions(rowValues).includes(column)));
  }, [rowValues, allOptions, getUsedOptions]);

  return (
    <>
      <div className='dialogContainer'>
        <div className='modalDialog importBaselineData'>
          <h3>{t('importBaselineData')}</h3>
          <div className='model-input'>
            <label
              className='file-label'
              htmlFor='model-name'>{t('model')}</label>
            <input
              className='model-name'
              disabled={true}
              id='model-name'
              type='text'
              value={modelName} />
          </div>
          <div className='file-input-container'>
            <FileUpload
              buttonText={t('browse')}
              fileName={fileName}
              initialValue={t('selectDataFile')}
              inputFile={inputFile}
              label={t('dataFile')}
              setFileName={setFileName}
              setInputFile={handleSetInputFile}
              setValidationMessage={setValidationMessage}
              t={t}
              validationMessage={validationMessage} />
          </div>
          <TableContainer className='baseline-data-table-container'>
            <Table
              className='baseline-data-table'
              size='small'>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className='column-header'>
                    {t('modelVariable')}
                  </TableCell>
                  <TableCell className='column-header'>
                    {t('dataColumn')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(rowValues).map(([variable, selected], rowIndex) => {
                  const rowOptions = [ ...availableOptions ];
                  if (selected !== defaultSelectedOption) {
                    rowOptions.splice(1, 0, selected);
                  }
                  return (
                    <TableRow
                      key={`row-container-${rowIndex}`}
                      hover={false}>
                      <TableCell className='row-header-cell'>
                        <div className='row-header-content'>
                          <div className='text'>{`${variable}${variable === schema.response.name ? ` ${t('responseNote')}` : ''}`}</div>
                          {selected === defaultSelectedOption && isValidFile && variable !== schema.response.name && <WarningIcon className='warning' /> }
                        </div>
                      </TableCell>
                      <TableCell className='row-input'>
                        {isValidFile
                          ? <TextField
                            className={classNames({ 'unselected-field': selected === defaultSelectedOption })}
                            fullWidth
                            select
                            value={selected}
                            onChange={({ target: { value } }) => setRowValues(prev => ({
                              ...prev,
                              [variable]: value
                            }))}>
                            {rowOptions.map((listValue, listIndex) =>
                              <MenuItem
                                key={`menu-item-${rowIndex}-${listIndex}`}
                                value={listValue}>
                                {listValue}
                              </MenuItem>
                            )}
                          </TextField>
                          : <TextField
                            disabled
                            fullWidth
                            select
                            value={t('noDataColumns')}>
                            <MenuItem
                              key={`menu-item-${rowIndex}-no-data`}
                              value={t('noDataColumns')}>
                              {t('noDataColumns')}
                            </MenuItem>
                          </TextField>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='buttons'>
            <button
              className='cancelButton'
              onClick={closeDialog}>
              {t('cancel')}
            </button>
            <button
              className='confirmButton'
              disabled={!isValidFile || validationMessage}
              onClick={handleSubmit}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
      {showFileSizeLimitDialog &&
        <ValidationDialog
          description={t('validateFileSizeLimit')}
          header={t('fileSizeLimit')}
          onSubmit={() => setShowFileSizeLimitDialog(false)} />}
      {showMissingPredictorsDialog &&
        <div className='dialogContainer'>
          <div className='modalDialog'>
            <h3>{t('missingPredictors')}</h3>
            <p>{t('oneOrMoreMissingPredictors')}</p>
            <div className='buttons'>
              <button
                className='cancelButton'
                onClick={() => setShowMissingPredictorsDialog(false) }>
                {t('cancel')}
              </button>
              <button
                className='confirmButton'
                onClick={async () => await importBaselineData() }>
                {t('ok')}
              </button>
            </div>
          </div>
        </div>}
      {showNoPredictorsDialog &&
        <ValidationDialog
          description={t('youMustSpecifyAPredictor')}
          header={t('noPredictorData')}
          onSubmit={() => setShowNoPredictorsDialog(false)} />}
    </>
  );
};

export default ImportBaselineDataDialog;
import { Autocomplete, FormControl, FormControlLabel, ListItem, ListItemText, RadioGroup, Radio } from '@mtb/ui';
import classNames from 'classnames';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { serializeError } from 'serialize-error';
import { formatModelNameForDisplay, isUniqueName } from '../utils/model-utils';
import { Statuses } from './Deployments';
import { ModelImport, importModel, publishModel } from './ModelImportDialog';
import LoadingWrapper from './LoadingWrapper';
import './dialogs.scss';
import { ErrorsContext, FlagsContext } from '../utils/context';

const MAX_NAME_LENGTH = 50;

const ModelSources = {
  Import    : 'importNewModel',
  Repository: 'selectFromRepository'
};

const Views = {
  SelectSource        : 'selectSource',
  ImportNewModel      : 'importNewModel',
  SelectFromRepository: 'selectFromRepository'
};

const getDialogTitle = (t, currentView) => {
  switch (currentView) {
    case Views.ImportNewModel:
      return t('newDeploymentImportModel');
    case Views.SelectFromRepository:
      return t('newDeploymentSelectModel');
    case Views.SelectSource:
    default:
      return t('newDeployment');
  }
};

const NavigationButtons = props => {
  const [t] = useTranslation();
  const {
    showBack = true,
    showCancel = true,
    disableConfirm = false,
    confirmText = t('next'),
    onBack,
    onCancel,
    onConfirm
  } = props;

  return (
    <div className='button-row'>
      <div className="buttons">
        {showBack && (
          <button
            className="cancelButton"
            type="button"
            onClick={onBack}>
            {t('back')}
          </button>
        )}
      </div>
      <div className={classNames('buttons', { 'create': showBack, 'source': !showBack })}>
        {showCancel && (
          <button
            className="cancelButton"
            type="button"
            onClick={onCancel}>
            {t('cancel')}
          </button>
        )}
        <button
          className="confirmButton"
          disabled={disableConfirm}
          type="button"
          onClick={onConfirm}>
          {confirmText}
        </button>
      </div>
    </div>
  );
};

const SelectFromSourceDialog = props => {
  const {
    closeDialog,
    deployments,
    deploymentName,
    defaultDeploymentName,
    setDefaultDeploymentName,
    modelSource,
    setModelSource,
    setDeploymentName,
    setCurrentView
  } = props;

  const [t] = useTranslation();
  const [validationMessage, setValidationMessage] = useState();

  const onConfirm = () => {
    setValidationMessage();
    if (!deploymentName.trim()) {
      setValidationMessage(t('validateDeploymentNameIsRequired'));
    } else if (!isUniqueName(deployments, deploymentName)) {
      setValidationMessage(t('deploymentNamesMustBeUnique'));
    } else {
      setCurrentView(modelSource);
    }
  };

  const validateName = () => {
    if (!deploymentName.trim().length) {
      setDeploymentName(defaultDeploymentName);
    }
  };

  useEffect(() => {
    if (deployments && !defaultDeploymentName) {
      let deploymentNum = deployments.length + 1;
      const currentNames = deployments.map(({ name }) => name.toLowerCase());
      while (currentNames.includes(t('untitledDeployment', { num: deploymentNum }).toLowerCase())) {
        deploymentNum++;
      }
      const availableName = t('untitledDeployment', { num: deploymentNum });
      setDefaultDeploymentName(availableName);
      setDeploymentName(availableName);
    }
  }, [t, deployments, defaultDeploymentName, setDefaultDeploymentName, setDeploymentName]);

  return <>
    <div className="formControl challenger">
      <label
        className="deployment-label"
        htmlFor="deploymentName">
        {t('deploymentName')}
      </label>
      <div className="inputWithText">
        <input
          id="deploymentName"
          maxLength={MAX_NAME_LENGTH}
          tabIndex="0"
          type="text"
          value={deploymentName}
          onBlur={validateName}
          onChange={({ target: { value } }) => setDeploymentName(value)} />
        {validationMessage && (
          <div className="validationMessage">{validationMessage}</div>
        )}
      </div>
      <FormControl className='source-group'>
        <label className="deployment-label">
          {t('modelSource')}
        </label>
        <RadioGroup row>
          {Object.values(ModelSources).map(source =>
            <FormControlLabel
              key={source}
              className='source-control'
              control={(
                <Radio
                  checked={modelSource === source}
                  size='small'
                  value={source}
                  onChange={({ target: { value } }) => setModelSource(value)} />
              )}
              label={t(source)} />)}
        </RadioGroup>
      </FormControl>
    </div>
    <NavigationButtons
      showBack={false}
      onCancel={closeDialog}
      onConfirm={onConfirm} />
  </>;
};

const ImportNewModelDialog = props => {
  const {
    acceptedFileTypes,
    currentModels,
    reloadModels,
    closeDialog,
    createDeployment,
    creating,
    setCreating,
    modelName,
    setModelName,
    setCurrentView
  } = props;

  const [t] = useTranslation();

  const [project, setProject] = useState();
  const [model, setModel] = useState();
  const [defaultModelName, setDefaultModelName] = useState('');
  const [binaryThreshold, setBinaryThreshold] = useState(0.5);
  const [eventName, setEventName] = useState('Event');
  const [nonEventName, setNonEventName] = useState('Non-event');
  const [includeTrainingData, setIncludeTrainingData] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  const submitDialog = async () => {
    setCreating(true);
    const { id: championId, success } = await importModel({
      t,
      project,
      model,
      modelName: modelName || defaultModelName,
      binaryThreshold,
      eventName,
      nonEventName,
      defaultModelName,
      setDefaultModelName,
      includeTrainingData,
      setValidationMessage,
      reloadModels,
      onPublish: publishModel
    });
    setCreating(false);
    if (success) {
      const showBaselineDataNotCurrent = includeTrainingData && model.commandOutOfDate;
      await createDeployment(championId, showBaselineDataNotCurrent);
    }
  };

  return (
    <div className='import-native'>
      <ModelImport
        acceptedFileTypes={acceptedFileTypes}
        binaryThreshold={binaryThreshold}
        currentModels={currentModels}
        defaultModelName={defaultModelName}
        eventName={eventName}
        includeTrainingData={includeTrainingData}
        model={model}
        modelName={modelName}
        nonEventName={nonEventName}
        project={project}
        setBinaryThreshold={setBinaryThreshold}
        setBusy={setCreating}
        setDefaultModelName={setDefaultModelName}
        setEventName={setEventName}
        setIncludeTrainingData={setIncludeTrainingData}
        setModel={setModel}
        setModelName={setModelName}
        setNonEventName={setNonEventName}
        setProject={setProject}
        setValidationMessage={setValidationMessage}
        validationMessage={validationMessage} />
      <NavigationButtons
        confirmText={t(creating ? 'creatingAction' : 'createDeployment')}
        disableConfirm={creating}
        onBack={() => setCurrentView(Views.SelectSource)}
        onCancel={closeDialog}
        onConfirm={submitDialog} />
    </div>
  );
};

const SelectFromRepositoryDialog = props => {
  const {
    closeDialog,
    createDeployment,
    setCurrentView,
    creating,
    models,
    modelName,
    setModelName
  } = props;

  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [readyModels, setReadyModels] = useState();
  const [validationMessage, setValidationMessage] = useState();

  useEffect(() => {
    const modelNames = models.reduce((prev, model) => {
      if (model.status === Statuses.Published && !model.deployment) {
        prev.push(formatModelNameForDisplay(model.name));
      }
      return prev;
    }, []);
    setReadyModels(modelNames.sort());
  }, [models]);

  const onConfirm = async () => {
    setValidationMessage();
    if (!modelName || !readyModels.includes(modelName)) {
      setValidationMessage(t('validateModelNameIsRequired'));
    } else {
      await createDeployment();
    }
  };

  return <>
    <div className="formControl challenger">
      <label className="deployment-label">
        {t('model')}
      </label>
      <Autocomplete
        className="autocomplete"
        id="modelName"
        inputValue={inputValue}
        options={readyModels}
        placeholder={t('selectAModel')}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemText>{option}</ListItemText>
          </ListItem>
        )}
        onChange={(_, newValue) => setModelName(newValue)}
        onInputChange={(_, newInputValue) => setInputValue(formatModelNameForDisplay(newInputValue))} />
      {validationMessage &&
        <div className="validationMessage">{validationMessage}</div>}
    </div>
    <NavigationButtons
      confirmText={t(creating ? 'creatingAction' : 'createDeployment')}
      disableConfirm={creating}
      onBack={() => setCurrentView(Views.SelectSource)}
      onCancel={closeDialog}
      onConfirm={onConfirm} />
  </>;
};

const CreateDeployment = ({ deployments, models, reloadModels, onCancel, onCreate }) => {
  const [t] = useTranslation();
  const flags = useContext(FlagsContext);
  const { onError } = useContext(ErrorsContext);

  const [currentView, setCurrentView] = useState(Views.SelectSource);
  const [modelSource, setModelSource] = useState(ModelSources.Import);
  const [deploymentName, setDeploymentName] = useState('');
  const [defaultDeploymentName, setDefaultDeploymentName] = useState('');
  const [modelName, setModelName] = useState('');
  const [creating, setCreating] = useState(false);

  const closeDialog = () => onCancel();

  const createDeployment = async (championId, showBaselineDataNotCurrent) => {
    setCreating(true);
    try {
      const id = championId ?? models.find(model => formatModelNameForDisplay(model.name) === modelName)?.id;
      const metadata = { name: deploymentName, champion: id };
      await onCreate(metadata, showBaselineDataNotCurrent);
      closeDialog();
    } catch (e) {
      onError(serializeError(e));
    } finally {
      setCreating(false);
    }
  };

  return (
    <div className="dialogContainer">
      <div
        className="modalDialog challengerInput"
        role="dialog">
        <h3>{getDialogTitle(t, currentView)}</h3>
        <LoadingWrapper
          caption={t('loadingModelDetails')}
          className='centered'
          isLoading={!deployments || !models} />
        {currentView === Views.SelectSource && (
          <SelectFromSourceDialog
            closeDialog={closeDialog}
            defaultDeploymentName={defaultDeploymentName}
            deploymentName={deploymentName}
            deployments={deployments}
            modelSource={modelSource}
            setCurrentView={setCurrentView}
            setDefaultDeploymentName={setDefaultDeploymentName}
            setDeploymentName={setDeploymentName}
            setModelSource={setModelSource} />
        )}
        {currentView === Views.ImportNewModel && (
          <ImportNewModelDialog
            acceptedFileTypes={flags.enablespm ? ['.MPX', '.GRV'] : ['.MPX']}
            closeDialog={closeDialog}
            createDeployment={createDeployment}
            creating={creating}
            currentModels={models}
            modelName={modelName}
            reloadModels={reloadModels}
            setCreating={setCreating}
            setCurrentView={setCurrentView}
            setModelName={setModelName} />
        )}
        {currentView === Views.SelectFromRepository && (
          <SelectFromRepositoryDialog
            closeDialog={closeDialog}
            createDeployment={createDeployment}
            creating={creating}
            modelName={modelName}
            models={models}
            setCurrentView={setCurrentView}
            setModelName={setModelName} />
        )}
      </div>
    </div>
  );
};

export default CreateDeployment;

import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChevronPager from './ChevronPager';
import { CategoricalExpandedView, ContinuousExpandedView } from './ExpandedView';
import {
  barchartBaselineConfig,
  barchartProductionConfig,
  histogramBaselineConfig,
  histogramProductionConfig,
  timeSeriesCollapsedConfig
} from '../utils/chart-options';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CollapseIcon,
  DriftStatusMinimalIcon,
  DriftStatusModerateIcon,
  DriftStatusSevereIcon,
  ExpandIcon
} from '../images';
import { toLocalizedString } from '../utils/locales';
import { DefaultSettings, sortHelper } from '../utils/model-utils';
import { FlagsContext, SettingsContext } from '../utils/context';

const MAX_PREDICTORS_PER_PAGE = 20;
const MIN_DATA_ROWS = 10;

const Properties = {
  Drift       : 'drift',
  Status      : 'status',
  VariableName: 'name'
};

const getDriftStatusIcon = status => {
  switch (status) {
    case 'minimal':
      return <DriftStatusMinimalIcon className="statusIcon" />;
    case 'moderate':
      return <DriftStatusModerateIcon className="statusIcon" />;
    case 'severe':
      return <DriftStatusSevereIcon className="statusIcon" />;
    default:
  }
};

const getVariableData = (variable, psi, schema) => {
  const psi_timeseries = {
    psi_values : [],
    time_values: []
  };
  if (psi[variable.name.toLowerCase()]) {
    psi_timeseries.psi_values = psi[variable.name.toLowerCase()];
    psi_timeseries.time_values = psi.label;
  }
  return {
    ...variable,
    ...(variable.type === 'categorical' && { categories: (schema[variable.name].classes).sort() }),
    psi_timeseries,
    dataType: schema[variable.name].dataType
  };
};

const TableHeader = props => {
  const {
    variableType,
    sortable,
    sortAscending,
    sortProperty,
    sortBy
  } = props;

  const [t] = useTranslation();

  const getSortIcon = () => {
    return sortAscending ?
      <ArrowDownIcon className="sortIcon" /> :
      <ArrowUpIcon className="sortIcon" />;
  };

  return (
    <div className="header-row">
      <div className="hiddenCell" />
      <div
        className={classNames('wideCell', { sortable })}
        onClick={() => sortBy?.(Properties.VariableName)}>
        {sortProperty === Properties.VariableName && getSortIcon()}
        <span>{t(variableType)}</span>
      </div>
      <div
        className={classNames('wideCell', { sortable })}
        onClick={() => sortBy?.(Properties.Status)}>
        {sortProperty === Properties.Status && getSortIcon()}
        <span>{t('drift')}</span>
      </div>
      <div className="wideCell">
        <span>{t('baselineData')}</span>
      </div>
      <div className="wideCell">
        <span>{t('productionData')}</span>
      </div>
      <div
        className={classNames('mediumCell align-right', { sortable })}
        onClick={() => sortBy?.(Properties.Drift)}>
        {sortProperty === Properties.Drift && getSortIcon()}
        {variableType === 'predictorVariable' ?
          <span>{t('dataDriftPSI')}</span> :
          <span>{t('predictionDriftPSI')}</span>
        }
      </div>
      <div className="wideCell">
        <span>{t('driftOverTime')}</span>
      </div>
    </div>
  );
};

const TableRow = props => {
  const {
    expanded,
    setExpanded,
    metadata,
    variable,
    promotions
  } = props;

  const [t] = useTranslation();
  const settings = useContext(SettingsContext);

  const [baselineOptions, setBaselineOptions] = useState();
  const [productionOptions, setProductionOptions] = useState();
  const [timeSeriesOptions, setTimeSeriesOptions] = useState();
  const flags = useContext(FlagsContext);

  const continuous = variable.type === 'continuous';
  const hasBaselineData = variable.baseline?.number >= MIN_DATA_ROWS;
  const hasProductionData = variable.production?.number >= MIN_DATA_ROWS;
  const hasTimeSeriesData = variable.psi_timeseries.psi_values?.some(x => !!x || x === 0);
  const hasStatus = variable.status && variable.status !== 'unknown';

  useEffect(() => {
    // Use identical x-axis scale between baseline/production mini histograms
    if (variable.type === 'continuous') {
      const baselineXVals = variable.baseline?.data?.bin_locations ?? [];
      const productionXVals = variable.production?.data?.bin_locations ?? [];
      variable.xMin = Math.min(...baselineXVals.concat(productionXVals));
      variable.xMax = Math.max(...baselineXVals.concat(productionXVals));
    }

    // Use identical y-axis scale between baseline/production mini histograms
    const baselineYMax = variable.baseline?.data?.values ?? [];
    const productionYMax = variable.production?.data?.values ?? [];
    variable.yMax = Math.max(...baselineYMax.concat(productionYMax)) * 100;
  }, [metadata, variable]);

  useEffect(() => {
    if (metadata && variable && promotions) {
      if (hasBaselineData) {
        const baselineConfig = continuous ? histogramBaselineConfig : barchartBaselineConfig;
        setBaselineOptions(baselineConfig(
          t,
          {
            name      : t('baseline'),
            data      : variable.baseline.data,
            categories: variable.categories,
            xMin      : variable.xMin,
            xMax      : variable.xMax,
            yMax      : variable.yMax
          },
          variable.name,
          variable.dataType
        ));
      }

      if (hasProductionData) {
        const productionConfig = continuous ? histogramProductionConfig : barchartProductionConfig;
        setProductionOptions(productionConfig(
          t,
          {
            name      : t('production'),
            data      : variable.production.data,
            categories: variable.categories,
            xMin      : variable.xMin,
            xMax      : variable.xMax,
            yMax      : variable.yMax
          },
          variable.name,
          variable.dataType
        ));
      }

      if (hasTimeSeriesData) {
        setTimeSeriesOptions(timeSeriesCollapsedConfig(
          t,
          {
            name: t('driftPSI'),
            data: variable.psi_timeseries
          },
          {
            moderate: metadata.moderate_drift_threshold ?? 0.1,
            severe  : metadata.severe_drift_threshold ?? 0.2,
          },
          promotions,
          flags.region,
          flags.timezone ? metadata.timezone ?? DefaultSettings.Timezone : undefined,
        ));
      }
    }
  }, [t, flags.region, flags.timezone, metadata, continuous, variable,
    promotions, hasBaselineData, hasProductionData, hasTimeSeriesData]);

  return (
    <div>
      <div
        className={classNames('data-row', { 'no-border': expanded && hasBaselineData })}
        onClick={() => setExpanded(!expanded)}>
        <div className="hiddenCell">
          {expanded ?
            <CollapseIcon className="expand-icon" /> :
            <ExpandIcon className="expand-icon" />}
        </div>
        <div className="wideCell">
          <label>{variable.name}</label>
        </div>
        <div className="wideCell status">
          {hasStatus && (
            <div className="icon-container">
              {getDriftStatusIcon(variable.status)}
            </div>
          )}
          <div
            className={classNames('text', { 'no-status': !hasStatus })}
            title={!hasStatus ? t('notEnoughDriftData') : ''}>
            {t(hasStatus ? variable.status : 'noStatus')}
          </div>
        </div>
        <div className="wideCell">
          {hasBaselineData ?
            <div
              className="collapsed-graph"
              id={`${variable.name}-collapsed-baseline`}>
              <HighchartsReact
                highcharts={Highcharts}
                options={baselineOptions ?? {}} />
            </div> :
            <div
              className='no-data'
              title={t('notEnoughBaselineData')}>
              {t('notEnoughData')}
            </div>}
        </div>
        <div className="wideCell">
          {hasProductionData ?
            <div
              className="collapsed-graph"
              id={`${variable.name}-collapsed-production`}>
              <HighchartsReact
                highcharts={Highcharts}
                options={productionOptions ?? {}} />
            </div> :
            <div
              className='no-data'
              title={t('notEnoughProductionData')}>
              {t('notEnoughData')}
            </div>}
        </div>
        <div
          className="mediumCell align-right psi"
          title={!toLocalizedString(variable.production.psi?.toFixed(6), settings.locale?.regionCode) ? t('notEnoughDriftData') : ''}>
          <span>{toLocalizedString(variable.production.psi?.toFixed(6), settings.locale?.regionCode) ?? '*'}</span>
        </div>
        <div className="wideCell">
          <div
            className="collapsed-graph"
            id={`${variable.name}-collapsed-time-series`}>
            {hasTimeSeriesData && timeSeriesOptions &&
              <HighchartsReact
                highcharts={Highcharts}
                options={timeSeriesOptions ?? {}} />}
          </div>
        </div>
      </div>
      {expanded &&
        <div className="expanded-view">
          {continuous ?
            <ContinuousExpandedView
              metadata={metadata}
              promotions={promotions}
              variable={variable} /> :
            <CategoricalExpandedView
              metadata={metadata}
              promotions={promotions}
              variable={variable} />}
        </div>}
    </div>
  );
};

export const ResponseVariableTable = props => {
  const {
    metadata,
    psi,
    response,
    promotions,
    schema,
    expanded,
    toggleExpanded
  } = props;

  const variable = useMemo(() => getVariableData(response, psi, schema), [response, psi, schema]);

  return (
    <div className="table">
      <TableHeader
        sortable={false}
        variableType='responseVariable' />
      <TableRow
        key={variable.name}
        expanded={expanded.includes(response.name)}
        metadata={metadata}
        promotions={promotions}
        setExpanded={() => toggleExpanded(response.name)}
        variable={variable} />
    </div>
  );
};

const getIndices = page => {
  const startIndex = (page - 1) * MAX_PREDICTORS_PER_PAGE;
  const endIndex = startIndex + MAX_PREDICTORS_PER_PAGE;
  return [startIndex, endIndex];
};

export const PredictorVariablesTable = props => {
  const {
    metadata,
    psi,
    predictors,
    promotions,
    schema,
    currentPage,
    setCurrentPage,
    expanded,
    toggleExpanded,
    sortProperty,
    sortAscending,
    toggleSort
  } = props;

  const [predictorIndices, setPredictorIndices] = useState(getIndices(currentPage));

  const getPSI = predictor => predictor.production.psi;

  const getSortFunc = useCallback(() => {
    if (predictors) {
      switch (sortProperty) {
        case Properties.Drift:
          return (a, b) => sortHelper(getPSI(a), getPSI(b), sortAscending);
        case Properties.Status:
          const sortOrder = { 'severe': 0, 'moderate': 1, 'minimal': 3, undefined: 4 };
          return (a, b) => sortHelper(sortOrder[a[sortProperty]], sortOrder[b[sortProperty]], sortAscending);
        default:
          return (a, b) => sortHelper(a[sortProperty], b[sortProperty], sortAscending);
      }
    }
    return () => 0;
  }, [sortProperty, sortAscending, predictors]);

  return (
    <div className="table">
      {predictors.length > MAX_PREDICTORS_PER_PAGE &&
        <ChevronPager
          currentPage={currentPage}
          pageSize={MAX_PREDICTORS_PER_PAGE}
          totalRows={predictors.length}
          onPageChange={page => {
            setCurrentPage(page);
            setPredictorIndices(getIndices(page));
          }} />}
      <TableHeader
        sortable={true}
        sortAscending={sortAscending}
        sortBy={toggleSort}
        sortProperty={sortProperty}
        variableType='predictorVariable' />
      {predictors
        ?.toSorted(getSortFunc())
        ?.slice(...predictorIndices)
        ?.map((predictor, id) => {
          return (
            <div key={id}>
              <TableRow
                key={predictor.name}
                expanded={expanded.includes(predictor.name)}
                metadata={metadata}
                promotions={promotions}
                setExpanded={() => toggleExpanded(predictor.name)}
                variable={getVariableData(predictor, psi, schema)} />
            </div>
          );
        })}
      {predictors.length > MAX_PREDICTORS_PER_PAGE &&
        <ChevronPager
          currentPage={currentPage}
          pageSize={MAX_PREDICTORS_PER_PAGE}
          totalRows={predictors.length}
          onPageChange={page => {
            setCurrentPage(page);
            setPredictorIndices(getIndices(page));
          }} />}
    </div>
  );
};
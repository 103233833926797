import React, { useState, useEffect, useContext } from 'react';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';
import EmbeddedLogo from './EmbeddedLogo';
import LoadingWrapper from './LoadingWrapper';
import ModelImportDialog, { publishModel } from './ModelImportDialog';
import { Scopes, requireScope } from '../utils/scopes';
import { extractMSSProjectModels } from '../utils/model-file-utils';
import Models from '../api/models';
import { ScopeContext } from '../utils/context';
import { HeartbeatStatus, SessionContext } from './SessionWrapper';

const DEFAULT_SEND_WIDTH = 610;
const DEFAULT_SEND_HEIGHT = 450;

const validOrigin = /^https:\/\/[^/]+?\.minitab.com([:/]|$)/;
const ancestor = window.location?.ancestorOrigins && window.location.ancestorOrigins[0];
const hosted =
  window.self !== window.top && validOrigin.test(document.referrer) && (!ancestor || validOrigin.test(ancestor));
let fetchHost;
let id = 0;
const pendingFetches = {};
if (hosted) {
  // because of the redirecting for auth   document.referrer can be connect-test which causes issues with message passing, so try to use ancestorOrigins which is only a chrome/edge thing
  window.addEventListener('message', ({ origin, data: { code, payload: { id, success, data } = {} } }) => {
    if (
      (ancestor && origin !== new URL(ancestor).origin) ||
      !validOrigin.test(origin) ||
      code !== 'MSS_EXTENSION_FETCH_RESPONSE' ||
      !pendingFetches[id]
    ) {
      return;
    }
    const { resolve, reject } = pendingFetches[id];
    delete pendingFetches[id];
    (success ? resolve : reject)(data);
  });
  fetchHost = (route, { method: verb = 'GET', ...options } = { method: 'GET' }) =>
    new Promise((resolve, reject) => {
      ++id;
      pendingFetches[id] = { resolve, reject };
      return window.parent.postMessage(
        {
          code   : 'MSS_EXTENSION_FETCH',
          payload: { id, verb, route, options },
        },
        ancestor || '*',
      );
    });
} else {
  fetchHost = async (route, { responseType, ...init } = { method: 'GET' }) => {
    const response = await fetch(route, init);
    if (responseType === 'blob') {
      return await response.blob();
    }
    const contentType = response.headers.get('content-type');
    const json = contentType && contentType.indexOf('application/json') !== -1;
    return json ? await response.json() : await response.text();
  };
}

const host = {
  show: async (height = DEFAULT_SEND_WIDTH, width = DEFAULT_SEND_HEIGHT, closeable = true) => {
    const dpr = window.devicePixelRatio;
    return await fetchHost(
        `/show?${new URLSearchParams({
          height: height * dpr,
          width : width * dpr,
          closeable,
        })}`,
        { method: 'POST' },
    );
  },
  getModel  : async () => await fetchHost('/api/model', { method: 'GET' }),
  getProject: async () => await fetchHost('/api/project', { method: 'GET', responseType: 'blob' }),
  close     : async () => await fetchHost('/close', { method: 'POST' }),
};

const EmbeddedUpload = () => {
  const [t] = useTranslation();
  const scope = useContext(ScopeContext);

  const [project, setProject] = useState();
  const [model, setModel] = useState();
  const [models, setModels] = useState([]);
  const [currentModels, setCurrentModels] = useState([]);
  const session = useContext(SessionContext);

  useEffect(() => {
    host.show(DEFAULT_SEND_HEIGHT, DEFAULT_SEND_WIDTH);
    let current = true;
    session?.heartbeatStatus !== HeartbeatStatus.Initializing && (async () => {
      const [project, model, { data: currentModels }] =
        await Promise.all([host.getProject(), host.getModel(), Models.getAll()]);
      const models = await extractMSSProjectModels(project);
      if (current) {
        const selectedModel = models
          .find(({ id, type }) => model.model_id.toString() === id && model.model_type === type);
        setModels([selectedModel]);
        setProject(project);
        setModel(selectedModel);
        setCurrentModels(currentModels);
      }
    })();
    return () => current = false;
  }, [session?.heartbeatStatus]);

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      host.close();
    }
  };

  const onPublish = async (model, metadata) => {
    try {
      const { success, id } = await publishModel(model, metadata);
      if (success) {
        host.close();
      }
      return { success, id };
    } catch (err) {
      if (err.body.errorCode) {
        // if we got a specific error, keep bubbling out
        throw err;
      }
      console.error(err);
      return { success: false };
    }
  };

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true
      }}>
      <div
        className='embedded'
        tabIndex={0}
        onKeyDown={handleKeyPress}>
        <div className="header">
          <div className="logo">
            <img
              alt="Logo"
              src={`data:image/png;base64,${EmbeddedLogo}`} />
          </div>
        </div>
        {requireScope(scope, Scopes.Models) ? (
          <>
            <LoadingWrapper
              caption={t('loadingModelDetails')}
              isLoading={!model || !project} />
            <ModelImportDialog
              acceptedFileTypes='.MPX'
              currentModels={currentModels}
              embedded
              initialModel={model}
              initialModels={models}
              initialProject={project}
              onCancel={() => host.close()}
              onPublish={onPublish} />
          </>
        ) : (
          <div className="dialogContainer">
            <div
              className="modalDialog"
              role="dialog">
              <h3>{t('cannotImportModel')}</h3>
              <p>{t('cannotImportModelDetails')}</p>
              <div className="buttons">
                <button
                  className="cancelButton"
                  type="button"
                  onClick={() => host.close()}>
                  {t('close')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </FocusTrap>
  );
};

export default EmbeddedUpload;
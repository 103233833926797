import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import Deployments from '../api/deployments';
import Settings from '../api/settings';
import { Statuses } from './Deployments';
import { MenuItem, Select } from '@mtb/ui';
import { round } from '../utils/model-utils';
import {
  serviceHealthConfig,
  responseTimeConfig,
  progressBarConfig
} from '../utils/chart-options';
import LoadingWrapper from './LoadingWrapper';
import './ServiceMetrics.scss';
import { FlagsContext, SettingsContext } from '../utils/context';
import { SessionContext, SessionStatus } from './SessionWrapper';

highchartsMore(Highcharts);

/**
 * Shortcuts for ISO-standard durations.
 */
const Duration = {
  sevenDays       : 'P7D',
  threeDays       : 'P3D',
  twoDays         : 'P2D',
  oneDay          : 'P1D',
  oneHour         : 'PT1H',
  fourHours       : 'PT4H',
  twentyFourHours : 'P1D',
  fourtyEightHours: 'P2D',
  thirtyMinutes   : 'PT30M',
  fiveMinutes     : 'PT5M'
};

const ServiceMetrics = props => {
  const {
    model,
    setBusy,
    metricPeriod,
    setMetricPeriod,
    metricAggregate,
    setMetricAggregate,
    deployment,
    t,
  } = props;
  const settings = useContext(SettingsContext);
  const session = useContext(SessionContext);

  const [serviceHealthSettings, setServiceHealthSettings] = useState({});
  const [responseTimeSettings, setResponseTimeSettings] = useState({});
  const [metricsLoaded, setMetricsLoaded] = useState(false);
  const flags = useContext(FlagsContext);

  const progressBar = useQuery(['progressbar', deployment.id], async () => {
    const { max_daily_scoring_limit: maxDailyScoringLimit } = await Settings.get();
    const scoreLogBlobSize = (await Deployments.getScoreLogSize(deployment.id)).rows;
    const percentUsed = round(scoreLogBlobSize / maxDailyScoringLimit * 100, 2);
    const storageUsed = {
      name  : t('storageUsed'),
      data  : percentUsed,
      suffix: '%'
    };
    const storageRemaining = {
      name  : t('storageRemaining'),
      data  : round(100.0 - percentUsed, 2),
      suffix: '%'
    };
    return progressBarConfig(t, storageUsed, storageRemaining);
  }, {
    enabled  : model?.status === Statuses.Deployed && session?.sessionStatus !== SessionStatus.Expired,
    staleTime: 60000
  });

  useEffect(() => {
    (async () => {
      if (!metricsLoaded) {
        setMetricsLoaded(false);
      }
      try {
        setBusy(true);
        const metrics = await Deployments.getMetrics(deployment.id, metricPeriod, metricAggregate);
        // Add start/end of time range into series
        const end = moment.utc();
        const start = moment.utc();
        start.subtract(moment.duration(Duration[metricPeriod]));
        metrics.responseTimes.data.push({ time: start.toISOString(), value: null });
        metrics.responseTimes.data.push({ time: end.toISOString(), value: null });
        setServiceHealthSettings(serviceHealthConfig(t, metrics.serviceHealth));
        setResponseTimeSettings(responseTimeConfig(t, metrics.responseTimes, flags.region, flags.timezone));
        setMetricsLoaded(true);
      } finally {
        setBusy(false);
      }
    })();
  }, [deployment, settings, metricPeriod, metricAggregate, metricsLoaded, setBusy, flags.region, flags.timezone, t]);

  const updateMetricsPeriod = async ({ target: { value } }) => {
    setMetricPeriod(value);
    await Deployments.patchDeployment(deployment.id, { service_metrics_period: value });
  };

  const updateAggregate = async ({ target: { value } }) => {
    setMetricAggregate(value);
    await Deployments.patchDeployment(deployment.id, { service_metrics_aggregate: value });
  };

  return (
    <div className="service-metrics">
      <h3>{t('service')}</h3>
      <div className="metricsArea">
        {model?.status !== Statuses.Deployed && (
          <p className="metricsStatusMessage">{t('deploymentMetricsPaused')}</p>
        )}
        {model?.status === Statuses.Deployed && <>
          {!metricsLoaded ? <LoadingWrapper inline /> :
            <>
              <div className="metric-controls">
                <Select
                  className='modeler-select-field'
                  value={metricPeriod}
                  onChange={updateMetricsPeriod}>
                  <MenuItem value="oneHour">{t('lastHour')}</MenuItem>
                  <MenuItem value="fourHours">{t('lastNumHours', { num: 4 })}</MenuItem>
                  <MenuItem value="oneDay">{t('lastDay')}</MenuItem>
                  <MenuItem value="twoDays">{t('lastNumDays', { num: 2 })}</MenuItem>
                  <MenuItem value="threeDays">{t('lastNumDays', { num: 3 })}</MenuItem>
                  <MenuItem value="sevenDays">{t('lastNumDays', { num: 7 })}</MenuItem>
                </Select>
                <Select
                  className='modeler-select-field'
                  value={metricAggregate}
                  onChange={updateAggregate}>
                  <MenuItem value="min">{t('min')}</MenuItem>
                  <MenuItem value="average">{t('average')}</MenuItem>
                  <MenuItem value="max">{t('max')}</MenuItem>
                </Select>
              </div>
              <div className="metric-cards">
                <div className="metric-card borderless">
                  <h4>{t('modelMetricsEndpointSummary')}</h4>
                  <HighchartsReact
                    allowChartUpdate={true}
                    containerProps={{ style: { width: '100%' } }}
                    highcharts={Highcharts}
                    options={serviceHealthSettings} />
                </div>
                <div className="metric-card borderless">
                  <h4>{t('modelmetricsReponseTime')}</h4>
                  <p>{t('modelmetricsReponseTimeDesc', { aggregateMethod: t(metricAggregate) })}</p>
                  <HighchartsReact
                    containerProps={{ style: { width: '100%' } }}
                    highcharts={Highcharts}
                    options={responseTimeSettings} />
                </div>
              </div>
            </>}
        </>}
      </div>
      <h3>{t('storage')}</h3>
      {model?.status !== Statuses.Deployed && (
        <p className="metricsStatusMessage">{t('deploymentMetricsPaused')}</p>
      )}
      {model?.status === Statuses.Deployed && <>
        {progressBar.status !== 'success' ? <LoadingWrapper inline /> :
          <div className="metricsArea">
            <div className="metric-cards">
              <div className='metric-card borderless'>
                <h4>{t('dailyStorageUtilization')}</h4>
                <HighchartsReact
                  containerProps={{ style: { width: '100%' } }}
                  highcharts={Highcharts}
                  options={progressBar.data} />
              </div>
            </div>
          </div>}
      </>}
    </div>
  );
};

export default ServiceMetrics;
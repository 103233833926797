import { Autocomplete, ListItem, ListItemText } from '@mtb/ui';
import React, { useEffect, useState, useContext } from 'react';
import { serializeError } from 'serialize-error';
import { useTranslation } from 'react-i18next';
import { Statuses } from './Deployments';
import { formatModelNameForDisplay } from '../utils/model-utils';
import Models from '../api/models';
import LoadingWrapper from './LoadingWrapper';
import './dialogs.scss';
import { ErrorsContext } from '../utils/context';

const toLowerCase = obj => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));

const validatePredictors = (deploymentPredictors, predictors) => {
  const modelPredictors = toLowerCase(predictors);
  const currentPredictors = toLowerCase(deploymentPredictors);
  const mismatchedPredictors = Object.keys(modelPredictors).reduce((prev, name) =>
    Object.keys(currentPredictors).includes(name) &&
    (currentPredictors[name].type !== modelPredictors[name].type ||
     currentPredictors[name].dataType !== modelPredictors[name].dataType) ?
      [...prev, name] : prev, []);
  return mismatchedPredictors.length === 0;
};

const validateResponse = (targetResponse, response) => {
  const targetIsBinary = targetResponse.classes?.length === 2;
  const isBinary = response?.classes?.length === 2;
  return response?.name &&
    (response?.classes?.length || 0) <= 50 &&
    targetResponse.type === response.type &&
    targetResponse.dataType === response.dataType &&
    targetResponse.name.toLowerCase() === response.name.toLowerCase() &&
    ((!targetResponse.classes && !response.classes) || targetIsBinary === isBinary);
};

const AddChallengerDialog = ({ addChallenger, models, cancel, champion, deployment }) => {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);

  const [selectedModel, setSelectedModel] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState();
  const [inputValue, setInputValue] = useState('');
  const [validationMessage, setValidationMessage] = useState();

  const addSelectedChallenger = async () => {
    setLoading(true);
    setValidationMessage();
    try {
      const modelId = models.find(model => formatModelNameForDisplay(model.name) === selectedModel)?.id;
      await addChallenger(modelId);
    } catch (e) {
      setSelectedModel();
      setValidationMessage(t('errorAddChallenger'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let current = true;
    (async () => {
      const { schema: { response: targetResponse } } = await Models.get(champion.id, 'schema');
      if (!targetResponse?.name) {
        return [];
      }

      // Check for models whose source and response match and are not already in a deployment.
      const eligible = [];
      for (const model of models) {
        try {
          if (current && model.status === Statuses.Published && !model.deployment && champion.source === model.source) {
            const { schema: { predictors, response } } = await Models.get(model.id, 'schema');
            if (validateResponse(targetResponse, response) &&
                validatePredictors(deployment.schema.predictors, predictors)) {
              eligible.push(formatModelNameForDisplay(model.name));
            }
          }
        } catch (e) {
          if (e.status === 404) {
            console.warn(`${model.id} has no schema`);
          } else {
            onError(serializeError(e));
          }
        }
      }

      if (current) {
        eligible.sort();
        setFilteredList(eligible);
      }
    })();

    return () => current = false;
  }, [champion.id, champion.source, deployment.schema.predictors, models, onError]);

  return (
    <div className="dialogContainer">
      <div className="modalDialog addModel">
        <h3>{t('addModel')}</h3>
        <LoadingWrapper
          caption={t('loadingModelDetails')}
          className='centered'
          isLoading={!filteredList} />
        <Autocomplete
          className="inputWithText autocomplete"
          disableClearable={true}
          id="modelName"
          inputValue={inputValue}
          label={t('model')}
          options={filteredList ?? []}
          placeholder={t('chooseModelFromRepository')}
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemText>{option}</ListItemText>
            </ListItem>
          )}
          onChange={(_, newValue) => setSelectedModel(newValue)}
          onInputChange={(_, newInputValue) => setInputValue(formatModelNameForDisplay(newInputValue))} />
        {validationMessage && (
          <div className="validationMessage">
            {validationMessage}
          </div>
        )}
        <div className="buttons">
          <button
            className="cancelButton"
            onClick={() => cancel()}>
            {t('cancel')}
          </button>
          <button
            className="confirmButton"
            disabled={!selectedModel || loading}
            onClick={addSelectedChallenger}>
            {t(loading ? 'loadingModelDetails' : 'ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddChallengerDialog;

import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { serializeError } from 'serialize-error';
import Models from '../api/models';
import ValidationDialog from './ValidationDialog';
import './dialogs.scss';
import { ErrorsContext } from '../utils/context';

const RenameModelDialog = ({
  allModels,
  modelName,
  modelId,
  setBusy,
  setShowRenameModelDialog,
  updateDeployment
}) => {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);
  const [name, setName] = useState(modelName);
  const [validationProps, setValidationProps] = useState();

  const handleRenameModel = async () => {
    if (allModels.some(model => model.name === name)) {
      setValidationProps({ header: t('duplicateModelName'), description: t('modelNamesMustBeUniqueEnterNewName') });
    } else if (!name.trim().length) {
      setValidationProps({ header: t('renameModel'), description: t('modelNamesMustBeEntered') });
    } else {
      try {
        setBusy(true);
        await Models.patchModel(modelId, { name });
        await updateDeployment();
      } catch (e) {
        onError(serializeError(e));
      } finally {
        setBusy(false);
        setShowRenameModelDialog(false);
        setValidationProps();
      }
    }
  };

  return (
    <>
      <div className="dialogContainer">
        <div className="modalDialog renameModel">
          <h3>{t('renameModel')}</h3>
          <p>{t('newModelName')}</p>
          <input
            maxLength={50}
            title={modelName}
            type="text"
            value={name}
            onChange={({ target: { value } }) => setName(value)} />
          <div className="buttons">
            <button
              className="cancelButton"
              onClick={() => setShowRenameModelDialog(false)}>
              {t('cancel')}
            </button>
            <button
              className="confirmButton"
              onClick={handleRenameModel}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
      {validationProps && (
        <ValidationDialog
          {...validationProps}
          onSubmit={() => setValidationProps()} />
      )}
    </>
  );
};

export default RenameModelDialog;
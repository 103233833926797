import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from '@mtb/ui';
import { getBinDateFormat } from '../utils/chart-utils';
import { FlagsContext } from '../utils/context';
import { formatShortLocalDateTime } from '../utils/locales';

const isDateRangeCurrent = (start, end) => {
  const currentMoment = moment.utc();
  return moment(currentMoment).isBetween(start, end, undefined, '[]');
};

const BinNavigation = (props) => {
  const {
    additionalInfo,
    binDateRange,
    binIndex,
    totalBins,
    setBinIndex,
    setIsCurrentDate,
    timezone
  } = props;

  const [t] = useTranslation();
  const [disableMoveForward, setDisableMoveForward] = useState();
  const [disableMoveBackward, setDisableMoveBackward] = useState();
  const flags = useContext(FlagsContext);

  const formattedDateRange = useMemo(() => {
    const formatting = getBinDateFormat(flags.region, true);
    return {
      start: flags.timezone ? formatShortLocalDateTime(binDateRange.start, timezone) :
        moment(binDateRange.start).utc().format(formatting),
      end: flags.timezone ? formatShortLocalDateTime(binDateRange.end, timezone) :
        moment(binDateRange.end).utc().format(formatting)
    };
  }, [timezone, binDateRange.end, binDateRange.start, flags.region, flags.timezone]);

  useEffect(() => {
    setDisableMoveForward(binIndex === totalBins - 1);
    setDisableMoveBackward(binIndex === 0);
    const { start, end } = binDateRange;
    setIsCurrentDate(isDateRangeCurrent(start, end));
  }, [binIndex, totalBins, binDateRange, setIsCurrentDate]);

  return (
    <div className="period-selector">
      <div className="period-label-and-toggle-buttons">
        <label>{t('dateRange', formattedDateRange)}</label>
        {additionalInfo}
        <div className="nav-buttons">
          <Button
            disabled={disableMoveBackward}
            onClick={() => setBinIndex(binIndex - 1)}>
            {t('previous')}
          </Button>
          <Button
            disabled={disableMoveForward}
            onClick={() => setBinIndex(binIndex + 1)}>
            {t('next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BinNavigation;
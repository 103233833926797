import classNames from 'classnames';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { serializeError } from 'serialize-error';
import { useTranslation } from 'react-i18next';
import { convertToUTC } from '../utils/model-utils';
import LoadingWrapper from './LoadingWrapper';
import { MenuItem, Select } from '@mtb/ui';
import {
  AuditResultFailed,
  AuditResultSuccess,
  RefreshIcon,
  TriangleDown,
} from '../images';
import ChevronPager from './ChevronPager';

import './grids.scss';
import './AuditLog.scss';
import { ErrorsContext } from '../utils/context';

const AUDIT_LOG_PAGE_SIZE = 10;

const AuditLog = ({ id, getAuditLog, userSettings, updateSettings, setBusy, busy, allowRefresh }) => {
  const [t] = useTranslation();
  const { onError } = useContext(ErrorsContext);

  const [auditData, setAuditData] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const { auditLogFilter: filter, auditLogPage: currentPage } = userSettings;

  const refreshLog = useCallback(async () => {
    try {
      setBusy(true);
      const logData = await getAuditLog(id, currentPage, filter);
      setTotalRows(logData.data?.totalRecords ?? 0);
      setExpandedRows([]);
      setAuditData(logData.data);
    } catch (e) {
      onError(serializeError(e));
    } finally {
      setBusy(false);
    }
  }, [filter, currentPage, getAuditLog, id, setBusy, onError]);

  const toggleDetails = useCallback((id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter(i => i !== id));
    } else {
      setExpandedRows(expandedRows.concat(id));
    }
  }, [expandedRows]);

  const getFormattedDetails = useCallback((rawDetails) => {
    if (!rawDetails || rawDetails.length === 0) {
      return t('auditlogNoDetails');
    }
    return JSON.stringify(JSON.parse(Buffer.from(rawDetails, 'base64').toString()), 0, 2);
  }, [t]);

  const getActionResultIcon = useCallback(result => {
    switch (result) {
      case 'Success':
        return <AuditResultSuccess className="auditGridIcon" />;
      case 'Failed':
        return <AuditResultFailed className="auditGridIcon" />;
      default:
    }
  }, []);

  useEffect(() => {
    // Don't call the API on initial load until user settings populate
    if (currentPage !== undefined) {
      refreshLog();
    }
  }, [refreshLog, currentPage]);

  return (
    (busy || !Object.keys(userSettings).length) ?
      <LoadingWrapper
        caption={t('loadingAuditLog')}
        isLoading={busy && !Object.keys(userSettings).length} /> :
      <div className="audit-log">
        <div className="audit-log-header">
          <div className="audit-log-filter">
            <Select
              className="modeler-select-field"
              value={filter}
              onChange={({ target: { value } }) => {
                updateSettings({ auditLogFilter: value, auditLogPage: 1 });
              }}>
              <MenuItem value="all">{t('entriesAll')}</MenuItem>
              <MenuItem value="failed">{t('entriesFailed')}</MenuItem>
              <MenuItem value="success">{t('entriesSuccessful')}</MenuItem>
            </Select>
            {allowRefresh && (
              <div
                className="refresh-button"
                onClick={() => updateSettings({ auditLogPage: 1 })}>
                <RefreshIcon />
                {t('refresh')}
              </div>
            )}
          </div>
          <ChevronPager
            currentPage={currentPage}
            disabled={busy}
            label='entries'
            pageSize={AUDIT_LOG_PAGE_SIZE}
            totalRows={totalRows}
            onPageChange={(page) => updateSettings({ auditLogPage: page })} />
        </div>

        <div>
          {(!auditData?.pageData?.length && !busy) &&
          <p>{t('auditlogNoRecords')}</p>
          }
          {(auditData?.pageData?.length > 0 && !busy) &&
          <div className="auditLogTable table">
            <div className="header-row">
              <div className="hiddenCell"></div>
              <div className="wideCell">
                <span>{t('timestamp')}</span>
              </div>
              <div className="extraWideCell">
                <span>{t('user')}</span>
              </div>
              <div className="extraWideCell">
                <span>{t('action')}</span>
              </div>
              <div className="smallCell">
                <span>{t('result')}</span>
              </div>
            </div>

            {auditData?.pageData?.map((row, id) => {
              return (
                <span key={id}>
                  <div
                    className="data-row"
                    onClick={() => toggleDetails(id)}>
                    <div
                      className="hiddenCell"
                      title={t('clickToView')}>
                      <span className={classNames('detailsExpander', { 'open': expandedRows.includes(id) })}><TriangleDown /></span>
                    </div>
                    <div className="wideCell">
                      {convertToUTC(row.timestamp, true)}
                    </div>
                    <div className="extraWideCell">
                      {row.username}
                    </div>
                    <div className="extraWideCell">
                      {row.action}
                    </div>
                    <div className="smallCell iconCell">
                      {getActionResultIcon(row.result)}
                      {row.result}
                    </div>
                  </div>
                  {expandedRows.includes(id) && (
                    <div
                      key={`detail-${id}`}
                      className="data-details-row">
                      <pre className="dataDisplay">
                        {getFormattedDetails(row.payload)}
                      </pre>
                    </div>
                  )}
                </span>
              );
            })}
          </div>
          }
        </div>
      </div>
  );
};

export default AuditLog;